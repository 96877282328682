<template>
  <div id="delete-appointment">
    {{ appointmentId }} {{ patientId }} {{ appointmentNote }}
    <b-form
      id="form-delete-appointment"
      @submit.prevent="deleteAppointment"
    >
      <b-row>
        <b-col>
          <label>HN</label>
          <b-form-input
            v-model="hn"
          />
        </b-col>
        <b-col>
          <label>Name</label>
          <b-form-input
            v-model="name"
            disabled
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>Clinic</label>
          <v-select
            v-model="clinic"
            :options="clinicOptions"
            class="w-100"
            disabled
            :reduce="name => name.code"
            label="name"
            placeholder="select clinic"
          />
        </b-col>
        <b-col>
          <label>Doctor</label>
          <v-select
            v-model="doctor"
            :options="doctorOptions"
            class="w-100"
            disabled
            :reduce="name => name.code"
            label="name"
            placeholder="select doctor"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="startDate">Date</label>
          <b-form-datepicker
            id="dateAppointment"
            v-model="dateNewAppointment"
            :min="nextDay"
            :date-disabled-fn="dateDisabled"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            @input="getSchedulePeriod"
          />
        </b-col>
        <b-col>
          <label>Period</label>
          <v-select
            v-model="scheduleSelected"
            :options="scheduleLists"
            class="w-100"
            :reduce="name => name.id"
            label="name"
            placeholder="select schedule"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>Service</label>
          <v-select
            v-model="service"
            :options="serviceOptions"
            class="w-100"
            :disabled="scheduleDate === ''"
            :reduce="name => name.code"
            label="name"
            placeholder="select service"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="startDate">Note</label>
          <b-form-textarea
            v-model="appointmentNote"
            size="md"
            placeholder="Type your details here .."
          />
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            class="mr-1"
            size="sm"
            @click="exitForm"
          >
            Cancel
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            size="sm"
          >
            Make
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    clinic: {
      type: String,
      default: () => '',
    },
    doctor: {
      type: String,
      default: () => '',
    },
    clinicOptions: {
      type: Array,
      default: () => [],
    },
    doctorOptions: {
      type: Array,
      default: () => [],
    },
    doctorCodeId: {
      type: Number,
      default: () => 0,
    },
    // baseSchedule: {
    //   type: Array,
    //   default: () => [],
    // },
    serviceOptions: {
      type: Array,
      default: () => [],
    },
    dateAppointment: {
      type: String,
      default: () => '',
    },
    // dayAppointment: {
    //   type: String,
    //   default: () => '',
    // },
    appointmentId: {
      type: Number,
      dafault: () => 0,
    },
    patientId: {
      type: Number,
      dafault: () => 0,
    },
  },
  data() {
    return {
      curdate: new Date(),
      nextDay: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      scheduleSelected: '',
      scheduleLists: [],
      scheduleAllLists: [],
      hn: '',
      name: '',
      scheduleDate: '',
      service: '',
      appointmentNote: '',
      baseSchedule: [],
    }
  },
  created() {
    this.dateNewAppointment = this.dateAppointment
    this.checkDateAppointment()
  },
  methods: {
    exitForm() {
      this.$emit('exit', 'formCreateAppointmentModal')
    },
    checkDateAppointment() {
      if (this.dateNewAppointment !== '') {
        this.getSchedulePeriod()
      }
    },
    getPatientName() {
      // console.log('Get PT name')
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13
    },
    getSchedulePeriod() {
      this.scheduleSelected = ''
      this.$http({
        url: '/api/DoctorSchedule/DailyDoctorSchedule',
        method: 'POST',
        data: {
          DoctorCodeID: this.doctorCodeId,
          Day: '',
          DateFilter: this.dateNewAppointment,
        },
      }).then(({ data }) => {
        // this.patientLists = data
        this.baseSchedule = []
        const dataSchedule = data
        for (let s = 0; s < dataSchedule.scheduleDetails.length; s += 1) {
          if (dataSchedule.scheduleDetails[s].minPerPatient > 0) {
            // // console.log(dataSchedule.scheduleDetails[s].scheduleDetail)
            this.baseSchedule.push(dataSchedule.scheduleDetails[s].scheduleDetail)
          }
        }
        // // console.log(this.baseSchedule.length)
        this.designSchedule()
      })
    },
    designSchedule() {
      const tempSchedule = []
      for (let i = 0; i < this.baseSchedule.length; i += 1) {
        const scheduleDetails = this.baseSchedule[i]
        // // console.log(scheduleDetails)
        const clickDate = new Date(this.dateNewAppointment).getTime()
        const startUse = new Date(scheduleDetails.startUse).getTime()
        const endUse = new Date(scheduleDetails.endUse).getTime()
        // console.log(clickDate, startUse, endUse)
        if (startUse <= clickDate && (endUse >= clickDate || endUse === 0)) {
          tempSchedule.push(
            {
              id: scheduleDetails.scheduleID,
              name: `${scheduleDetails.day} ${scheduleDetails.startTime.substr(0, 5)} - ${scheduleDetails.endTime.substr(0, 5)}`,
              numOfPatient: scheduleDetails.numOfPatient,
              useDate: scheduleDetails.startUse.substr(0, 10),
            },
          )
        }
      }
      this.scheduleLists = tempSchedule
    },
    deleteAppointment(e) {
      e.preventDefault()
      // if (this.daySelected.length === 0) {
      //   return
      // }
      // if (this.amountOfPatient <= 0) {
      //   return
      // }

      this.$http({
        url: '/api/Appointment/Appointment',
        method: 'POST',
        data: {
          appointment: {
            AppointmentID: this.appointmentId,
            ScheduleID: this.scheduleSelected,
            PatientID: this.patientId,
            AppointmentDate: this.dateNewAppointment,
            AppointmentNote: this.appointmentNote,
            ComeForCode: '',
            AppointmentStatus: 3,
            isActive: true,
          },
        },
      }).then(() => {
        // console.log(data)
        this.$swal({
          title: 'Appointment Updated.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$emit('exit', 'formUpdateAppointmentModal')
          }
        })
      })
    },
  },
}
</script>

<template>
  <div id="form-make-appointment">
    <b-row>
      <b-col class="text-left">
        <h5 class="text-primary">CLinic Name</h5>
      </b-col>
      <b-col class="text-left">
        <h5 class="text-primary">Dr. Name</h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        Patient Info
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label for="startDate">Date</label>
        <b-form-datepicker
          id="startDate"
          v-model="startDate"
          :min="nextDay"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          required
        />
      </b-col>
      <b-col>
        <label>Schedule</label>
        <v-select
          v-model="scheduleSelected"
          :options="scheduleLists"
          class="w-100"
          :reduce="name => name.id"
          label="name"
          placeholder="select schedule"
          @input="showScheduleInfo"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label>Service</label>
        <v-select
          v-model="service"
          :options="clinicOptions"
          class="w-100"
          :reduce="name => name.code"
          label="name"
          placeholder="select clinic"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label for="startDate">Note</label>
        <b-form-textarea
          v-model="appointmentNote"
          size="md"
          placeholder="Type your details here .."
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    clinicName: {
      type: String,
      default: () => '',
    },
    doctorName: {
      type: String,
      default: () => '',
    },
    doctorCodeId: {
      type: Number,
      default: () => 0,
    },
    baseSchedule: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      curdate: new Date(),
      nextDay: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      appointmentNote: '',
    }
  },
  created() {
    this.designSchedule()
  },
  methods: {
    designSchedule() {
      const tempSchedule = []
      for (let i = 0; i < this.baseSchedule.length; i += 1) {
        const scheduleDetails = this.baseSchedule[i]
        for (let s = 0; s < scheduleDetails.data.length; s += 1) {
          this.scheduleAllLists.push(scheduleDetails.data[s])
          tempSchedule.push(
            {
              id: scheduleDetails.data[s].scheduleID,
              name: `${scheduleDetails.data[s].day} ${scheduleDetails.data[s].startTime.substr(0, 5)} - ${scheduleDetails.data[s].endTime.substr(0, 5)}`,
              numOfPatient: scheduleDetails.data[s].numOfPatient,
              useDate: scheduleDetails.data[s].startUse.substr(0, 10),
            },
          )
        }
      }
      this.scheduleLists = tempSchedule
    },
    exitForm() {
      this.$emit('exit', 'formMakeAppointmentModal')
    },
  },
}
</script>

<style>

</style>

<template>
  <div id="appointment">
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
          >
            <label>Clinic</label>
            <v-select
              v-model="clinic"
              :options="clinicOptions"
              class="w-100"
              :reduce="name => name.code"
              label="name"
              placeholder="select clinic"
              @input="changeRoute()"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
          >
            <label>Doctor</label>
            <v-select
              v-model="doctor"
              :options="doctorOptions"
              class="w-100"
              :disabled="clinic === ''"
              :reduce="name => name.code"
              label="name"
              placeholder="select doctor"
              @input="getPatientLists()"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="mr-1"
              @click="showCreateAppointmentForm"
            >
              <feather-icon
                icon="PlusCircleIcon"
                class="mr-50"
              />
              <span class="align-middle">New Appoinement</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            sm="12"
            class="my-1"
          />
          <b-col
            md="6"
            class="my-1"
          >
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <!-- @row-clicked="showUpdateAppointmentForm" -->
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <!-- <b-dropdown-item @click="showMakeAppointmentForm(data)">
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Make Appointment</span>
                  </b-dropdown-item> -->

                  <b-dropdown-item @click="showUpdateAppointmentForm(data)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit Appointment</span>
                  </b-dropdown-item>

                  <b-dropdown-item>
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete Appointment</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-col>

          <b-col
            cols="6"
          >
            <b-form-group
              class="mb-0"
            >
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Modal Make Appointment -->
    <b-modal
      id="formMakeAppointmentModal"
      hide-footer
      centered
      size="lg"
      title="Make Appointment"
    >
      <form-make-appointment
        :clinic-name="clinicName"
        :doctor-name="doctorName"
        :doctor-code-id="doctorCodeID"
        :base-schedule="baseSchedule"
        @exit="onCloseModal"
      />
    </b-modal>
    !-- Modal Create Appointment -->
    <b-modal
      id="formCreateAppointmentModal"
      hide-footer
      centered
      size="lg"
      title="Make Appointment"
    >
      <form-create-appointment
        :clinic="clinic"
        :doctor="doctor"
        :clinic-options="clinicOptions"
        :doctor-options="doctorOptions"
        :doctor-code-id="doctorCodeID"
        :base-schedule="baseSchedule"
        @exit="onCloseModal"
      />
    </b-modal>
    !-- Modal Update Appointment -->
    <b-modal
      id="formUpdateAppointmentModal"
      hide-footer
      centered
      size="xl"
      title="Update Appointment"
    >
      <form-update-appointment
        @exit="onCloseModal"
      />
    </b-modal>
    !-- Modal Dekete Appointment -->
    <b-modal
      id="formDeleteAppointmentModal"
      hide-footer
      centered
      size="xl"
      title="Delete Appointment"
    >
      <form-delete-appointment
        @exit="onCloseModal"
      />
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import FormMakeAppointment from './FormMakeAppointment.vue'
import FormCreateAppointment from './FormCreateAppointment.vue'
import FormUpdateAppointment from './FormUpdateAppointment.vue'
import FormDeleteAppointment from './FormDeleteAppointment.vue'

export default {
  components: {
    vSelect,
    FormMakeAppointment,
    FormCreateAppointment,
    FormUpdateAppointment,
    FormDeleteAppointment,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      hn: '',
      clinic: '',
      doctor: '',
      clinicOptions: [],
      doctorOptions: [],
      doctorCodeIDLists: [],
      patientSelected: '',
      patientLists: [],
      n_hn: '',
      preID: '',
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'visitNumber', label: 'Visit number', sortable: true },
        { key: 'hn', label: 'HN', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'queue', label: 'Queue', sortable: true },
        { key: 'paymentMethod', label: 'Payment method', sortable: true },
        { key: 'appointmentDate', label: 'Appointment date', sortable: true },
        { key: 'appointmentTime', label: 'Appointment time', sortable: true },
        { key: 'actions' },
      ],
      items: [
        {
          id: 1,
          visitNumber: '003',
          hn: '0000007 ',
          name: 'Bill gate',
          queue: 'W001',
          paymentMethod: 'ผู้ป่วยทั่วไป (ชำระเงินเอง OPD)',
          appointmentDate: 'Wed 8th Oct 2018',
          appointmentTime: '8:10 AM',
        },
        {
          id: 2,
          visitNumber: '003',
          hn: '0000007 ',
          name: 'Bill gate',
          queue: 'W001',
          paymentMethod: 'ผู้ป่วยทั่วไป (ชำระเงินเอง OPD)',
          appointmentDate: 'Wed 8th Oct 2018',
          appointmentTime: '8:10 AM',
        },
        {
          id: 3,
          visitNumber: '003',
          hn: '0000007 ',
          name: 'Bill gate',
          queue: 'W001',
          paymentMethod: 'ผู้ป่วยทั่วไป (ชำระเงินเอง OPD)',
          appointmentDate: 'Wed 8th Oct 2018',
          appointmentTime: '8:10 AM',
        },
        {
          id: 4,
          visitNumber: '003',
          hn: '0000007 ',
          name: 'Bill gate',
          queue: 'W001',
          paymentMethod: 'ผู้ป่วยทั่วไป (ชำระเงินเอง OPD)',
          appointmentDate: 'Wed 8th Oct 2018',
          appointmentTime: '8:10 AM',
        },
        {
          id: 5,
          visitNumber: '003',
          hn: '0000007 ',
          name: 'Bill gate',
          queue: 'W001',
          paymentMethod: 'ผู้ป่วยทั่วไป (ชำระเงินเอง OPD)',
          appointmentDate: 'Wed 8th Oct 2018',
          appointmentTime: '8:10 AM',
        },
        {
          id: 6,
          visitNumber: '003',
          hn: '0000007 ',
          name: 'Bill gate',
          queue: 'W001',
          paymentMethod: 'ผู้ป่วยทั่วไป (ชำระเงินเอง OPD)',
          appointmentDate: 'Wed 8th Oct 2018',
          appointmentTime: '8:10 AM',
        },
        {
          id: 7,
          visitNumber: '003',
          hn: '0000007 ',
          name: 'Bill gate',
          queue: 'W001',
          paymentMethod: 'ผู้ป่วยทั่วไป (ชำระเงินเอง OPD)',
          appointmentDate: 'Wed 8th Oct 2018',
          appointmentTime: '8:10 AM',
        },
        {
          id: 8,
          visitNumber: '003',
          hn: '0000007 ',
          name: 'Bill gate',
          queue: 'W001',
          paymentMethod: 'ผู้ป่วยทั่วไป (ชำระเงินเอง OPD)',
          appointmentDate: 'Wed 8th Oct 2018',
          appointmentTime: '8:10 AM',
        },
        {
          id: 9,
          visitNumber: '003',
          hn: '0000007 ',
          name: 'Bill gate',
          queue: 'W001',
          paymentMethod: 'ผู้ป่วยทั่วไป (ชำระเงินเอง OPD)',
          appointmentDate: 'Wed 8th Oct 2018',
          appointmentTime: '8:10 AM',
        },
        {
          id: 10,
          visitNumber: '003',
          hn: '0000007 ',
          name: 'Bill gate',
          queue: 'W001',
          paymentMethod: 'ผู้ป่วยทั่วไป (ชำระเงินเอง OPD)',
          appointmentDate: 'Wed 8th Oct 2018',
          appointmentTime: '8:10 AM',
        },
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  async created() {
    this.getClinicByReg()
    if (this.$route.params.clinic && this.$route.params.clinic !== 'null') {
      this.clinic = this.$route.params.clinic
      await this.getDoctorByClinic()
      if (this.$route.params.doctor) {
        this.doctor = this.$route.params.doctor
      }
    }
  },
  methods: {
    changeRoute() {
      this.$router.push(`/nurse/appointment/${this.clinic}`).catch(() => {})
      this.patientLists = []
      this.getDoctorByClinic()
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showMakeAppointmentForm() {
      this.$bvModal.show('formMakeAppointmentModal')
    },
    showCreateAppointmentForm() {
      this.$bvModal.show('formCreateAppointmentModal')
    },
    showUpdateAppointmentForm() {
      this.$bvModal.show('formUpdateAppointmentModal')
    },
    showDeleteAppointmentForm() {
      this.$bvModal.show('formDeleteAppointmentModal')
    },
    onCloseModal(id) {
      // console.log(id)
      this.$bvModal.hide(id)
    },
    getClinicByReg() {
      this.isScheduleHeaderActive = false
      this.isScheduleMenuActive = false
      this.$httpCustom({
        // url: '/api/ClinicDoctor/getClinicFromReg',
        url: '/api/ClinicDoctor/getClinicFromReg',
        method: 'GET',
        data: {},
      }).then(({ data }) => {
        const result = []
        for (let i = 0; i < data.length; i += 1) {
          const clinicname = `${data[i].clinicCode} - ${data[i].clinicName}`
          result.push({ id: i + 1, code: data[i].clinicCode, name: clinicname })
        }
        this.clinicOptions = result
      })
    },
    getDoctorByClinic() {
      this.doctor = null
      this.$httpCustom({
        // url: `/api/Doctor/All/${this.clinic}`,
        url: `/api/Doctor/All/${this.clinic}`,
        method: 'GET',
        data: {},
      }).then(({ data }) => {
        const result = []
        const result2 = []
        for (let i = 0; i < data.length; i += 1) {
          const docname = `${data[i].docCode} - ${data[i].docName}`
          result.push({ id: i + 1, code: data[i].docCode, name: docname })
          result2.push({ id: data[i].doctorCodeID, code: data[i].docCode })
        }
        this.doctorOptions = result
        this.doctorCodeIDLists = result2
        // // console.log(result2)
      })
    },
    getFollowUpDetail() {
      this.patientLists = []
      this.$http({
        url: '/api/Appointment/AppointmentDetail',
        method: 'POST',
        data: {
          DateFilter: '2021-04-25',
          PatientID: 0,
          ScheduleID: 2823,
        },
      }).then(({ data }) => {
        this.patientLists = data
      })
    },
  },
}
</script>
